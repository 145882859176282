.Button,
.IconButton {
  @apply inline-flex justify-center items-center select-none;
  gap: var(--dop-space-2xs);
}

/* 공통 커서 처리 */
.Button:disabled,
.Button[role='button'][disabled],
.IconButton:disabled,
.IconButton[role='button'][disabled] {
  cursor: not-allowed;
}

/* Variant - outline */
.Button.outline {
  color: var(--dop-button-outline-text-base);
  /** 백그라운드 색상은 고정 */
  background-color: var(--dop-color-bg-basic-base);
}

/* Variant - ghost */
.Button.ghost {
  /** 백그라운드 색상은 고정 */
  background-color: var(--dop-color-bg-basic-base);
}

/** color */
/* solid.color-level1 */
.Button.solid.colorLevel1 {
  color: var(--dop-color-text-basic-white);
  background-color: var(--dop-color-button-bg-primary-level1);
  border: 1px solid var(--dop-color-button-bg-primary-level1);
}
/* solid.color-level1:hover */
.Button.solid.colorLevel1:hover {
  color: var(--dop-color-text-basic-white);
  background-color: var(--dop-color-button-bg-primary-level1-hover);
  border: 1px solid var(--dop-color-button-bg-primary-level1-hover);
}

/* solid.color-level2 */
.Button.solid.colorLevel2 {
  color: var(--dop-color-text-primary-level1);
  background-color: var(--dop-color-button-bg-primary-level2);
  border: 1px solid var(--dop-color-button-bg-primary-level2);
}

/* solid.color-level2:hover */
.Button.solid.colorLevel2:hover {
  color: var(--dop-color-text-primary-level1-hover);
  background-color: var(--dop-color-button-bg-primary-level2-hover);
  border: 1px solid var(--dop-color-button-bg-primary-level2-hover);
}

/* solid.color-critical */
.Button.solid.colorCritical {
  color: var(--dop-color-text-basic-white);
  background-color: var(--dop-color-button-bg-basic-critical, #ee2e2e);
  border: 1px solid var(--dop-color-button-bg-basic-critical, #ee2e2e);
}
/* solid.color-critical:hover */
.Button.solid.colorCritical:hover {
  color: var(--dop-color-text-basic-white);
  background-color: var(--dop-color-button-bg-basic-critical-hover, #c22525);
  border: 1px solid var(--dop-color-button-bg-basic-critical-hover, #c22525);
}

/* solid.color-level1:disabled, solid:color-level2:disabled, solid:color-critical:disabled*/
.Button.solid.colorLevel1:disabled,
.Button.solid.colorLevel1[disabled],
.Button.solid.colorLevel2:disabled,
.Button.solid.colorLevel2[disabled],
.Button.solid.colorCritical:disabled,
.Button.solid.colorCritical[disabled] {
  color: var(--dop-color-text-primary-disabled);
  background-color: var(--dop-color-button-bg-primary-disabled);
  border: 1px solid var(--dop-color-button-bg-primary-disabled);
}

/* outline.color-level1 */
.Button.outline {
  border: 1px solid var(--dop-color-button-border-level1, #cfd1d4);
}

/* outline.color-level1:hover */
.Button.outline:hover {
  border: 1px solid var(--dop-color-button-border-basic-level1, #cfd1d4);
  background-color: var(--dop-color-button-bg-basic-hover);
}

/* outline.color-level1:disabled */
.Button.outline:disabled,
.Button.outline[disabled] {
  color: var(--dop-color-text-basic-disabled);
  border: 1px solid var(--dop-color-button-border-basic-disabled);
}

/* ghost.color-level1 */
.Button.ghost {
  color: var(--dop-color-text-basic-level1);
}

/* ghost:hover (level1, level2 동일) */
.Button.ghost:hover,
.IconButton:hover {
  background-color: var(--dop-color-button-bg-basic-hover);
}

/* ghost.color-level1:disabled */
.Button.ghost:disabled,
.Button.ghost[disabled] {
  color: var(--dop-color-text-basic-disabled);
}

/* Size - small */
.Button.small {
  @apply text-2xs;
  height: 26px;
  padding: 0px 8px;
}

/* Size - medium */
.Button.medium {
  @apply text-sm;
  height: 40px;
  padding: 0px 16px;
}

/* Size - large */
.Button.large {
  @apply text-base font-medium;
  height: 56px;
  padding: 0px var(--dop-space-xl);
  gap: var(--dop-space-sm);
}

/* autosize - auto */
.Button.autoWidth {
  width: auto;
}

/* autosize - auto */
.Button.fullWidth {
  width: 100%;
}

/* Shape - rect (small) */
.Button.rect.small {
  border-radius: 4px;
}

/* Shape - rect (medium) */
.Button.rect.medium {
  border-radius: 8px;
}

/* Shape - rect (large) */
.Button.rect.large {
  border-radius: 12px;
}

/* Shape - round */
.Button.round {
  @apply rounded-full;
}

/* IconButton - padding만 정의하고 버튼 크기는 아이콘 크기로 정해지도록 한다. */
.IconButton {
  padding: var(--dop-space-sm); /* 8px */
}

/** IconButton 크기별 모서리 처리 */
.IconButton.xxlarge,
.IconButton.xlarge {
  padding: var(--dop-space-sm); /* 8px */
  border-radius: 24px;
}

.IconButton.large,
.IconButton.medium {
  padding: var(--dop-space-sm); /* 8px */
  border-radius: 16px;
}

.IconButton.small {
  padding: var(--dop-space-2xs); /* 4px */
  border-radius: 8px;
}

.IconButton.xsmall {
  padding: 3px;
  border-radius: 6px;
}

/* .IconButton:disabled */
.IconButton:disabled,
.IconButton[disabled] {
  color: var(--dop-color-text-basic-disabled);
}

/** IconButton - toggle */
.IconButton .indicator {
  color: var(--dop-color-gray-30); /* rgea(170 170 170 / 1) */
}
.IconButton.activated .indicator {
  @apply rotate-180;
}
