.ToastMessage {
  @apply rounded-lg shadow-lg flex justify-between p-4 gap-x-4;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  background-color: var(--dop-color-bg-basic-black);

  .body {
    @apply grow self-center;
  }
  .close {
    @apply shrink-0 self-start;
    color: var(--dop-color-white);
    /** 레거시 (go_app_style.css에 button의 배경색이 white로 강제 적용되어 있어서 이를 다시 재정의 필요 **/
    background-color: var(--dop-color-bg-basic-black);
  }

  &[data-size='sm'],
  &.sm {
    width: 300px;
  }

  &[data-size='md'],
  &.md {
    width: 400px;
  }

  &[data-size='lg'],
  &.lg {
    width: 500px;
  }

  /** 현재는 타입별 구분이 없음 */
  &[data-type='info'],
  &.info,
  &[data-type='message'],
  &.message,
  &[data-type='warning'],
  &.warning,
  &[data-type='alert'],
  &.alert {
    color: var(--dop-color-white);
    background-color: var(--dop-color-bg-basic-black);
  }
}
