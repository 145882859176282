.Content {
  display: flex;
  max-width: 240px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background-color: #545960;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
  color: #e5ebf3;
}

.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
