@container (max-width: 250px) {
  .CardItem {
    width: 100%;
  }
}

@container (min-width: 251px) {
  .CardItem {
    width: calc(50% - 8px);
  }
  .CardList > .CardItem:nth-child(odd):last-child {
    width: 100%;
  }
}

.CardList {
  container-type: inline-size;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 16px;
  padding: 16px 24px;
  width: 100%;
}

.CardItem {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 17px;
  background: var(--palette-cool-gray-cool-gray5, #f9fbfc);
  cursor: pointer;
  gap: 16px;
}

.CardHeader {
  display: flex;
  gap: 16px;
  max-width: 100%;
}

.Sender {
  display: flex;
  flex-direction: column;
  gap: 1px;

  max-width: calc(100% - 48px);
}
.SenderName {
  color: var(--Font, #25262c);

  font-size: var(--font-size-Regular, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-multi-line-height-Regular, 24px); /* 150% */
  letter-spacing: var(--font-letter-spacing-Regular, -0.32px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SenderEmail {
  color: var(--color-text-level3, #999);

  font-size: var(--font-size-XSmall, 13px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-multi-line-height-XSmall, 19.5px); /* 150% */
  letter-spacing: var(--font-letter-spacing-XSmall, -0.52px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Contents {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Subject {
  overflow: hidden;
  color: var(--color-text-level1, #363636);
  text-overflow: ellipsis;

  font-size: var(--font-size-Regular, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-multi-line-height-Regular, 24px); /* 150% */
  letter-spacing: var(--font-letter-spacing-Regular, -0.32px);
  line-clamp: 2;
  -webkit-line-clamp: 2;
  max-height: 48px;
}

.Preview {
  overflow: hidden;
  color: var(--color-text-level2, #5b5b5b);
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: var(--font-size-Small, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-multi-line-height-Small, 21px); /* 150% */
  letter-spacing: var(--font-letter-spacing-Small, -0.28px);
}
