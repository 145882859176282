.Dialog {
  background-color: white;
  border-radius: 12px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  padding: var(--dop-space-xl, 24px);
  z-index: var(--z-index-dialog, 999); /** 토큰명은 변경될 수 있음 */
  /* overflow: hidden; */
}

.Dialog_PosCenter {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Dialog_PosTop {
  left: 50%;
  top: var(--dop-size-lg, 16px);
  transform: translate(-50%, 0);
}

.Dialog_PosBottom {
  left: 50%;
  bottom: var(--dop-size-lg, 16px);
  transform: translate(-50%, 0);
}

.Dialog__Overlay {
  @apply inset-0;
  background-color: black;
  position: fixed;
  inset: 0;
  opacity: 0.5;
  z-index: var(--z-index-dialog, 999); /** 토큰명은 변경될 수 있음 */
  backdrop-filter: blur(16px);
}
.Dialog__Overlay_Open {
  animation: dialog-overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.Dialog__Title {
  width: 100%;
  font-size: var(--dop-font-size-xl, 20px);
  padding: 0 var(--dop-space-xl, 24px);
}

.Dialog__Close {
  @apply absolute top-4 right-4 w-10 h-10 flex items-center justify-center;
}

.Dialog__Close__Icon {
  @apply w-6 h-6;
}

.Dialog_Animation {
  animation-duration: 150ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}

.Dialog_PosCenterPopup {
  animation-name: dialog-center-popup;
}

.Dialog_EdgePopup {
  animation-name: dialog-edge-popup;
}

.Dialog_SlideUp {
  animation-name: dialog-slide-up;
}

.Dialog_SlideDown {
  animation-name: dialog-slide-down;
}

@keyframes dialog-overlay-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes dialog-center-popup {
  from {
    transform: translate(-50%, -50%) scale(0.76);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes dialog-edge-popup {
  from {
    transform: translate(-50%, 0) scale(0.76);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0) scale(1);
    opacity: 1;
  }
}

@keyframes dialog-slide-up {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dialog-slide-down {
  from {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
