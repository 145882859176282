@container (max-width: 250px) {
  .AssetItem {
    width: 100%;
  }
}
@container (min-width: 251px) {
  .AssetItem {
    width: calc(50% - 16px);
  }
}

@container (min-width: 750px) {
  .AssetItem {
    width: calc(33% - 8px);
  }
}

.AssetList {
  container-type: inline-size;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.AssetItem {
  display: flex;
  align-items: center;

  height: 34px;
  padding: 0px 8px;
  border-radius: 8px;
  cursor: pointer;
  gap: 4px;
}

.AssetItem:hover {
  background: #f2f2f2;
}

.AssetTitle {
  flex: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  font-size: var(--font-size-Small, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-multi-line-height-Small, 21px); /* 150% */
  letter-spacing: var(--font-letter-spacing-Small, -0.28px);
}

.AssetName {
  white-space: nowrap;
  overflow: hidden;
  color: var(--color-text-level1, #363636);
  text-overflow: ellipsis;
}
