.ToastRoot {
  &[data-state='open'][data-anchor='top-right'],
  &[data-state='open'][data-anchor='bottom-right'],
  &[data-state='open'][data-direction='right'] {
    animation: slide-in-right 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='open'][data-anchor='top-left'],
  &[data-state='open'][data-anchor='bottom-left'],
  &[data-state='open'][data-direction='left'] {
    animation: slide-in-left 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='open'][data-anchor='top'],
  &[data-state='open'][data-direction='up'] {
    animation: slide-down 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='open'][data-anchor='bottom'],
  &[data-state='open'][data-direction='down'] {
    animation: slide-up 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed'] {
    animation: hide 500ms ease-in;
  }

  &[data-swipe='end'][data-anchor='top-right'],
  &[data-swipe='end'][data-anchor='bottom-right'],
  &[data-swipe='end'][data-direction='right'] {
    animation: swipe-out-right 100ms ease-out;
  }

  &[data-swipe='end'][data-anchor='top-left'],
  &[data-swipe='end'][data-anchor='bottom-left'],
  &[data-swipe='end'][data-direction='left'] {
    animation: swipe-out-left 100ms ease-out;
  }

  &[data-swipe='end'][data-anchor='top'],
  &[data-swipe='end'][data-direction='up'] {
    animation: swipe-out-up 100ms ease-out;
  }

  &[data-swipe='end'][data-anchor='bottom'],
  &[data-swipe='end'][data-direction='down'] {
    animation: swipe-out-down 100ms ease-out;
  }

  &[data-swipe='move'][data-anchor='top-left'],
  &[data-swipe='move'][data-anchor='bottom-left'],
  &[data-swipe='move'][data-direction='left'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe='move'][data-anchor='top-right'],
  &[data-swipe='move'][data-anchor='bottom-right'],
  &[data-swipe='move'][data-direction='right'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe='move'][data-anchor='top'],
  &[data-swipe='move'][data-direction='up'] {
    transform: translateY(var(--radix-toast-swipe-move-y));
  }

  &[data-swipe='move'][data-anchor='bottom'],
  &[data-swipe='move'][data-direction='down'] {
    transform: translateY(var(--radix-toast-swipe-move-y));
  }

  &[data-swipe='cancel'][data-anchor='top-left'],
  &[data-swipe='cancel'][data-anchor='bottom-left'],
  &[data-swipe='cancel'][data-direction='left'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe='cancel'][data-anchor='top-right'],
  &[data-swipe='cancel'][data-anchor='bottom-right'],
  &[data-swipe='cancel'][data-direction='right'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe='cancel'][data-anchor='top'],
  &[data-swipe='cancel'][data-direction='up'] {
    transform: translateY(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe='cancel'][data-anchor='bottom'],
  &[data-swipe='cancel'][data-direction='down'] {
    transform: translateY(0);
    transition: transform 200ms ease-out;
  }
}

.ToastViewport {
  @apply fixed flex flex-col gap-2 z-[1000];

  &[data-anchor='top'],
  &[data-anchor='top-left'],
  &[data-anchor='top-right'] {
    @apply top-8;
  }

  &[data-anchor='top'] {
    @apply left-1/2 transform -translate-x-1/2;
  }
  &[data-anchor='top-left'] {
    @apply left-8;
  }
  &[data-anchor='top-right'] {
    @apply right-8;
  }

  &[data-anchor='bottom'],
  &[data-anchor='bottom-left'],
  &[data-anchor='bottom-right'] {
    @apply bottom-8;
  }

  &[data-anchor='bottom'] {
    @apply left-1/2 transform -translate-x-1/2;
  }
  &[data-anchor='bottom-left'] {
    @apply left-8;
  }
  &[data-anchor='bottom-right'] {
    @apply right-8;
  }

  &[data-size='sm'] {
    width: 300px;
  }
  &[data-size='md'] {
    width: 400px;
  }
  &[data-size='lg'] {
    width: 500px;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes swipe-out-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes swipe-out-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes swipe-out-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes swipe-out-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
