.SingleList {
  padding: 16px 24px;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SingleItem {
  padding: 8px;
  display: flex;
  border-radius: 8px;
  cursor: pointer;
  gap: 8px;
}

.SingleItem:hover {
  background: #f2f2f2;
}

.Contents {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.Header {
  max-width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.Title {
  overflow: hidden;
  color: #363636;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.28px;
}

.Date {
  color: var(--color-text-level3, #999);
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.48px;
}

.Preview {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: #363636;
  text-overflow: ellipsis;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: -0.52px;
}

.PreviewSeen {
  color: #999;
  font-style: normal;
  font-weight: 400;
}
