.Options {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
}

.Options:has(.Community + .Board + .Button) .Community {
  width: 100%;
}

.Options:has(.Community + .Button) .Community {
  width: calc(100% - 42px) !important;
}

.Board {
  width: calc(100% - 42px);
}
