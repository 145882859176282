.Fallback {
  @apply w-full h-full flex flex-col items-center;
}

.Fallback__ServiceMap {
  @apply w-full py-[8px] flex justify-center;
}

.Fallback__Viewport {
  @apply flex flex-col gap-y-[6px];
}

.Fallback__Button {
  @apply w-[64px] h-[64px] rounded-[20px];
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 95%);
  }
  100% {
    background-color: hsl(200, 20%, 80%);
  }
}
